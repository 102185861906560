button {
    border: none;
    font-family: var(--main-font-family);
    outline: none;
    box-shadow: none;
    cursor: pointer;

    &:disabled {
        filter: grayscale(1);
        cursor: not-allowed;
    }
}

.trans-btn {
    border: 1px solid var(--singleRecordCardBorder);
    width: 100%;
    background-color: transparent;
    padding: .7rem;
    text-align: center;
    color: var(--singleRecordCardBorder);
}

.main-button {
    width: 100%;
    background-color: var(--main-btn-bg);
    padding: .7rem;
    text-align: center;
    color: $white;
}

.cancel-btn {
    color: var(--main-btn-color);
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 3rem;
    display: inline-block;
    border: none;
    background: none;

    &:hover {
        color: $white;
    }
}

.show-more {
    width: 200px;
    max-width: 100%;
    align-self: center;
}