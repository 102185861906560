.add-comment {
  margin-inline-start: 3rem;
  margin-top: 2rem;

  &-input {
    textarea {
      font-size: 1.2rem;
      background-color: var(--comment-bg);
      border-radius: 1em;
      color: var(--input-color);
      padding: 2rem;
      border: none;
      width: 100%;
      resize: none;
    }
  }

  &-actions {
    display: flex;
    justify-content: flex-end;
    column-gap: 1rem;
    margin: .7rem;
    align-items: center;

    .audio-record .audio-recorder:not(.recording) .audio-recorder-mic {
      margin-inline-start: 0;
    }

    button {
      background-color: var(--main-btn-bg);
      color: var(--main-btn-color);
      border-radius: 0.25rem;
      padding: 0.25rem 0.5rem;
      font-size: 1.5rem;

      i {
        font-size: 1rem;
        color: var(--white);
      }
    }
  }

  &-audio {
    display: flex;
    justify-content: flex-end;
  }
}