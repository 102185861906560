.add-new-record {
    max-width: 100%;
    &-input {
        margin-bottom: 1rem;
        label {
            display: block;
            font-size: 1.5rem;
            margin-bottom: 1.5rem;
        }
        select {
            width: 100%;
            height: 3.5rem;
            padding: 0.5rem 1rem;
            font-size: 1.5rem;
            color: var(--main-color);
            border: none;
            outline: none;
            box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.06);
            &[class^="aya-"] {
                width: 40%;
            }
            option {
                color: var(--dark-bg);
            }
        }
        span {
            display: inline-block;
            width: 20%;
            font-size: 1.8rem;
            text-align: center;
        }
    }
    &-buttons {
        display: flex;
        justify-content: space-between;
        column-gap: 1rem;
        align-items: center;
    }
    button {
        background-color: var(--main-btn-bg);
        font-size: 1.4rem;
        padding: 1rem;
        width: 100%;
        color: var(--main-btn-color);
    }
    input#upload-file {
        display: none;
    }
    &-actions {
        display: flex;
        justify-content: space-between;
        column-gap: 1rem;
        margin-top: 2rem;
        button {
            padding: 1rem;
            width: 100%;
            &:nth-child(1) {
                background-color: var(--white-btn-bg);
                color: var(--white-btn-color);
            }
            &:nth-child(2) {
                background-color: var(--secondary-btn-bg);
                color: var(--secondary-btn-color);
            }
        }
    }
}