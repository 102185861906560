.profile-header {
    &-user {
        display: flex;
        align-items: flex-end;
        column-gap: 1.5rem;
        margin-bottom: 3.5rem;
        &-img {
            width: 11.5rem;
            height: 11.5rem;
            border-radius: 50%;
            background-color: var(--avatar-bg);
        }
        &-name {
            font-size: 2.8rem;
        }
        &-follow {
            background-color: var(--icon-light-gray-bg);
            padding: .7rem;
            font-size: 1rem;
            border-radius: 2px;
            color: $white;
            &.followed {
                color: var(--main-color);
            }
        }
    }
    &-following {
        padding: 1rem 10rem;
        margin: auto;
        background: linear-gradient(to left, var(--gradient-main-dark) -2%, var(--gradient-main-light) 102%);
        display: flex;
        column-gap: 11rem;
        &-followers, &-followings {
            display: flex;
            flex-direction: column;
            row-gap: .5rem;
            align-items: center;
        }
        &-followers {
            position: relative;
            &::after {
                position: absolute;
                content: "";
                top: 0;
                right: -5.5rem;
                width: 1px;
                height: 100%;
                background-color: var(--white-border);
            }
        }
    }
}
.user-records {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    margin-bottom: 2.5rem;
}