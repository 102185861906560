.verification-code-form {
  &-header {
    padding: 4rem 0;
    font-size: 1.2rem;
    text-align: center;
  }

  &-inputs {
    display: flex;
    justify-content: space-between;
    direction: ltr;

    .trans-input {
      width: 25%;
      text-align: center;
      height: 4rem;
      padding: .5rem;
      margin-right: 0;
    }
  }

  &-actions {
    display: flex;
    width: 100%;
    margin-top: 2.5rem;
    padding: 0 0rem;
  }
  &-cancel {
    text-align: center;
  }
}