.home-subscribe {
    padding: 6.5rem 2rem;
    background-color: var(--comment-bg);
    display: flex;
    flex-direction: column;
    align-items: center;
    &-text {
        font-size: 2.85rem;
        margin: 0;
        margin-bottom: 2rem;
    }
    &-input-group {
        display: flex;
        width: 100%;
        justify-content: center;
    }
    &-input {
        height: 50px;
        border-radius: .85rem;
        font-size: 20px;
        border: none;
        outline: none;
        padding: .25rem 1rem;
        width: 470px;
    }
    &-send-btn {
        background-color: var(--main-btn-bg);
        font-size: 2rem;
        border-radius: .715rem;
        margin-inline-start: -40px;
        color: var(--main-btn-color);
        height: 50px;
        padding: .25rem 2.85rem;
    }
}