.post {
  background-color: var(--post-bg);
  // margin-bottom: 2.5rem;
  padding: 1.5rem;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    &-user {
      display: flex;
      align-items: center;

      &-image {
        width: 4.8em;
        height: 4.8em;
        background-color: var(--avatar-bg);
        border-radius: 50%;
        overflow: hidden;

        img {
          height: 100%;
          width: 100%;
        }
      }

      &-name {
        margin-inline-start: 1.25rem;
        h3 {
          font-size: 2rem;
          font-weight: bold;
          margin-bottom: 1.5rem;
        }

        p {
          margin-bottom: 0;
        }
      }


    }
  }
  &-comments {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }

  &-text {
    margin: 1rem 0;
    font-size: 1.6rem;
    color: var(--comment-color);
  }

  &-feedback {
    display: flex;
    justify-content: space-between;

    &-likes-comments {
      display: flex;
      align-items: center;

      p {
        font-size: 1.2rem;
        color: var(--text-color);
        margin-inline-end: 1rem;
        margin-bottom: 0;
        &:not(:first-child) {
          margin-inline-start: 6rem;
        }
      }
      .fa-trash-can {
        margin-inline-start: 1.5rem;
      }
    }
  }

  i {
    color: var(--main-icon-color);
    font-size: 2.5rem;
    cursor: pointer;
  }
}