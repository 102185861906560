body {
  background-color: var(--main-bg);
  color: var(--text-color);
  margin: 0;
  overflow-x: hidden;

  * {
    box-sizing: border-box;
    font-family: var(--main-font-family);
    margin: 0;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
    margin-left: 0;
    text-transform: capitalize;
  }
}

html {
  font-size: 14px;
}

::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-track {
  background: var(--main-bg);
}

::-webkit-scrollbar-thumb {
  background-color: var(--secondary-btn-bg);
}

a {
  color: var(--text-color);
  text-decoration: none;

  &:hover {
    color: var(--text-color);
  }
}

.audio-source {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;

  audio,
  video {
    width: 100%;
    max-height: 60vh;
  }
}