.edit-profile {
    #upload-img {
        display: none;
    }
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    &-img {
        width: 11.5rem;
        height: 11.5rem;
        border-radius: 50%;
        overflow: hidden;
        align-self: center;
        position: relative;
        background-color: var(--avatar-bg);
        img {
            width: 100%;
            height: 100%;
        }
        &-upload {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color: var(--main-btn-bg);
            height: 2rem;
            font-size: .8rem;
            line-height: 2rem;
            text-align: center;
            vertical-align: middle;
            cursor: pointer;
        }
    }
    &-input-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 1.8rem;
        label {
            margin-bottom: 1.5rem;
        }
    }
}