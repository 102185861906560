.login-message {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    background-color: transparent;
}

.error-message {

    text-align: center;
    font-size: 3rem;
    width: 35rem;
    max-width: 100%;
    // word-break: break-all;

    i {
        color: var(--error-color);
    }

    p {
        padding: 1rem;
    }

    .error-message-cancel-btn {
        background-color: var(--error-color);
        color: var(--white);
        padding: .5rem;
        font-size: 1.125rem;
        font-weight: 600;
    }
}

.success-message {

    text-align: center;
    font-size: 3rem;
    width: 35rem;
    max-width: 100%;

    i {
        color: var(--main-color);
    }

    h4 {
        padding: 1rem;
        color: var(--main-color);
    }

    .success-message-cancel-btn {
        background-color: var(--main-color);
        color: var(--white);
        padding: .5rem;
        font-size: 1.125rem;
        font-weight: 600;
    }
}