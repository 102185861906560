@import './base';
@import './typography';
@import './buttons';
@import './media-query';
@import './inputs';

html[dir="rtl"] {
    @import './_ar-styles';
}

.add-new-record-buttons,
.add-comment-actions {
    .audio-recorder {
        display: none;
    }
}

.green-color {
    color: $green;
}

.error-color {
    color: var(--error-color) !important;
}