input {
    padding: .5rem 1rem;
    line-height: 1;
    text-transform: none;
    outline: none;
}
.trans-input {
    font-size: 1.8rem;
    background-color: transparent;
    border: 1px solid var(--white-border);
    color: var(--text-color);
    width: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}