.home-section {
    margin-bottom: 10rem;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2.85rem;

        &-title {
            font-size: 2rem;
        }

        &-action {
            font-size: 1.5rem;
            color: var(--text-color);
            text-decoration: underline;
            &:hover {
                color: var(--main-color);
            }
        }
    }

    &-content {
        display: flex;
        overflow-x: hidden;
        column-gap: 2.5rem;
    }
}