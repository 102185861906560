@import './variables';
@import './mixins';
@import './fonts';

:root {
    .dark-mode {
        @include create-css-vars($dark-vars);
    }

    .light-mode {
        @include create-css-vars($light-vars);
    }

    @include create-css-vars($main-colors);
    .zain-theme {
        @include create-css-vars($zain-theme);
    }

    .omantel-theme {
        @include create-css-vars($omantel-theme);
        // --main-color: #ff9d18;
        // --main-btn-bg: #ff9d18;
        // --main-icon-color: #ff9d18;
        // --white-btn-color: #ff9d18;
        // --gradient-main-dark: darken($orange, 30);
        // --gradient-main-light: darken($orange, 20);
    }

    .du-theme {
        @include create-css-vars($du-theme);
        // --main-color: #0086c3;
        // --main-btn-bg: #0086c3;
        // --main-icon-color: #0086c3;
        // --white-btn-color: #0086c3;
        // --gradient-main-dark: darken($du-blue, 30);
        // --gradient-main-light: darken($du-blue, 20);
    }
    .stc-theme {
        @include create-css-vars($stc-theme);
        // --main-color: #0086c3;
        // --main-btn-bg: #0086c3;
        // --main-icon-color: #0086c3;
        // --white-btn-color: #0086c3;
        // --gradient-main-dark: darken($du-blue, 30);
        // --gradient-main-light: darken($du-blue, 20);
    }
}