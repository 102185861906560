.login-wrapper {
    background-image: url("../../images/bg4.png");
    height: 100vh;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
        background-color: var(--main-color);
        opacity: 0.7;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1;
        content: "";
    }
}