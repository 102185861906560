.quran {
  scroll-behavior: smooth;
  &-title {
    font-size: 2rem;
    margin-bottom: 2rem;
    padding: 2rem;
  }

  &-content {
    display: flex;
    // text-align: center;
  }

  &-mobile-navigator {
    display: none;
    margin: 2rem auto;

    select {
      background: transparent;
      border: none;
      color: var(--text-color); //needs to change
      font-size: 1.75rem;
      font-family: var(--quran-font-family);

      &:focus {
        outline: none;
      }
    }

    option {
      color: var(--post-bg);
      background-color: var(--text-color);
    }
  }

  &-navigator {
    position: sticky;
    top: 0;
    width: 20%;
    border-right: 1px solid var(--border-color);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 1.6rem;
    background-color: var(--post-bg);
    padding: 0.5rem;
    overflow-y: auto;
    height: 100vh;

    &-surah {
      display: flex;
      column-gap: 1rem;
      cursor: pointer;
      padding: 0.7rem;
      width: 100%;

      p {
        font-family: var(--quran-font-family);
      }

      &.active {
        background-color: var(--main-bg);
        font-weight: 600;
        border-radius: 1rem;
        border: 1px solid var(--border-color);
      }
    }
  }

  &-view {
    width: 70%;
    display: flex;
    justify-content: center;
    margin: 0 auto;

    &-page-seprator {
      margin: 1rem 0 2rem 0;
      opacity: 0.5;

      p {
        font-size: 2rem;
        text-align: center;
        font-family: var(--quran-font-family);
      }
    }

    &-surah {
      font-size: 2.25rem;
      text-align: justify;
      text-transform: none;
      font-family: var(--quran-font-family);
      width: 55%;
      direction: rtl;
      &-ayah {
        font-family: var(--quran-font-family);
      }

      &-ayah-seperator {
        display: inline-block;
        width: 2.5rem;
        height: 2.5rem;
        vertical-align: middle;
        text-align: center;
        font-size: 1.5rem;
        line-height: 2.6rem;
        font-weight: bold;
        padding: 0;
        margin: 0.4rem;
        background: url("../../images/ayah.png");
        background-size: cover;
        background-repeat: no-repeat;
        font-family: var(--main-font-family);
      }

      &-openning {
        text-align: center;
        margin-bottom: 2rem;
        font-family: var(--quran-font-family);
      }

      &-name {
        text-align: center;
        font-family: var(--quran-font-family);
        margin-bottom: 2rem;
        font-size: 2.25rem;
        padding: 5px 0;
        border: 1px solid var(--border-color);
        border-radius: 5rem;
      }
    }
  }
}