
.page-not-found {
  &-content {
    z-index: 2;
    text-align: center;
    h2 {
      font-size: 7rem;
      font-weight: 900;
    }
    h6 {
      font-size: 2.5rem;
      font-weight: 600;
      margin-bottom: 2rem;
    }
    p {
      font-weight: bolder;
      font-size: 1.2rem;
    }
  }
}
