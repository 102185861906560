.footer {
    display: flex;
    justify-content: space-between;
    padding: 3rem;

    &-start {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &-links {
            display: flex;
            justify-content: space-between;
            column-gap: 6rem;
            &-col {
                &-header {
                    font-size: 1.85rem;
                    font-weight: 600;
                    margin-bottom: 1.4rem;
                    text-align: center;
                }

                ul {
                    list-style-type: none;
                    padding: 0;

                    li {
                        margin-bottom: .7rem;
                        text-align: center;
                        color: var(--main-color);

                        a {
                            color: var(--main-color);
                            text-decoration: none;
                        }
                    }
                }

                &:last-child {
                    ul {
                        font-size: 21px;
                    }
                }
            }
        }

        &-support {
            p {
                font-size: 1.28rem;
                color: var(--main-color);
                font-weight: 600;
                margin-bottom: .6rem;
            }
            &-logos {
                display: flex;
                justify-content: space-between;
                font-size: 1.5rem;
            }
        }

    }
    &-end {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        &-logo {
            max-width: 100px;
            img {
                width: 100%;
            }
        }
        &-social {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            &-logos {
                display: flex;
                justify-content: space-between;
                font-size: 1.5rem;
                margin-bottom: 1.5rem;
            }
            p {
                font-size: .8rem;
                color: var(--main-color);
            }
        }
    }
}