.audio-player {
    display: flex;
    width: 100%;
    background-color: var(--main-color);
    padding: .8rem 1.5rem;
    column-gap: 1.5rem;
    border-radius: 1.3rem;
    button.play-pause-btn {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: var(--play-pause-btn);
        i {
            color: var(--secondary-icon-color);
            font-size: 1.5rem;
        }
    }
}

[id^="waveform-"] {
    width: 100%;
    height: 50px;
}