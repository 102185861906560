.carousel {
  color: var(--carousel-color);
  margin-bottom: 2.5rem;

  &-content {
    position: absolute;
    left: 5rem;
    right: 5rem;
    bottom: 5.4rem;

    &-title {
      font-size: 2.85rem;
      font-weight: bold;
      margin-bottom: 1.8rem;
    }

    .register-now-btn {
      font-size: 1.2rem;
      font-weight: 600;
      background-color: var(--main-btn-bg);
      padding: 0.5rem 1.5rem;
      color: var(--main-btn-color);
      border-radius: 0.3rem;
    }

    .register-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: linear-gradient(to left, var(--gradient-main-dark) -2%, var(--gradient-main-light) 102%);
      padding: 1rem 2rem;

      p {
        font-size: 1.5rem;
        font-weight: bold;
        margin: 0;
      }

    }
  }

  &-item {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(177deg, #000 -32%, var(--dark-main-color) -20%, #000 95%);
      opacity: .8;
    }
  }

  &-indicators {
    position: static;
    justify-content: flex-start;
    margin: 3rem 0 1.8rem 0;

    button[data-bs-target] {
      width: 16px;
      height: 16px;
      border-radius: 50%;
    }
  }
}