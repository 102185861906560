.comment {
  margin-top: 2em;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2em;
    &-user {
      display: flex;
      align-items: center;

      &-image {
        width: 3em;
        height: 3em;
        background-color: var(--avatar-bg);
        border-radius: 50%;
        overflow: hidden;
        img {
          height: 100%;
          width: 100%;
        }
      }
      &-name {
        margin-inline-start: 1em;
        h3 {
          font-size: 1.3em;
          font-weight: bold;
        }
      }
      &-icon {
        margin-inline-start: 1em;
        i {
          font-size: 1rem;
        }
      }
    }
  }
  &:not(:last-child) {
    .comment-content {
      &::before {
        content: "";
        position: absolute;
        background-color: var(--border-color);
        width: 1px;
        top: -2em;
        bottom: -2em;
        left: -1.5em;
      }
    }
  }
  &-content {
    background-color: var(--comment-bg);
    padding: 1em;
    margin-inline-start: 3em;
    border-radius: 1em;
    position: relative;
    color: var(--comment-color);
    &-text {
      // color: var(--comment-color);
      font-size: 1.5em;
    }
    &-feedback {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1.5rem;
      &-likes {
        display: flex;
        align-items: center;
        column-gap: 1em;
        p {
          margin: 0;
        }
      }
    }
  }
}
