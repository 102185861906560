.single-record-card {
    // width: 18.5rem;
    // min-width: 18.5rem;
    height: 24rem;
    border-radius: 1.25rem;
    border: solid 1px var(--singleRecordCardBorder);
    position: relative;
    overflow: hidden;
    padding: 2px;
    cursor: pointer;

    &-img {
        min-width: 100%;
        background-image: url('../../../assets/images/quran.webp');
        height: 90%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        i {
            background-color: rgba(0,0,0, .4);
            border-radius: 50%;
            padding: 1rem 1.5rem;
            margin-top: 5rem;
        }
    }

    &-bg {
        display: flex;
        justify-content: center;
        background-color: var(--main-color);
        height: 100%;

        i {
            margin-top: 5rem;
        }
    }

    &-name {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 10rem;
        background: linear-gradient(to bottom, transparent, var(--single-record-gradient) 40%, var(--single-record-gradient));
        display: flex;
        justify-content: flex-end;
        font-size: 1.25rem;
        flex-direction: column;
        padding: 1rem;

        span {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            height: 60%;
            text-align: center;
            // padding: 0 1rem;
        }
    }
}