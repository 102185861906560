// .darkBG {
//     background-color: rgba(0, 0, 0, 0.6);
//     width: 100vw;
//     height: 100vh;
//     z-index: 5;
//     top: 0;
//     left: 0;
//     position: fixed;
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }

// .centered {
//     position: fixed;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
// }

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  max-width: 100vw;
}

.content {
  // position: relative;
  // z-index: 2;
  padding: 4rem 10rem;
  background-color: var(--popup-bg);
  display: flex;
  flex-direction: column;
  align-items: center;
  // text-align: center;
  // width: 450px;
  // min-width: 530px;
  border-radius: 5px;
  // max-height: 100vh;
  // overflow-y: auto;
  // width: 70vw;
  // max-width: 90vw;
  max-height: 90vh;
  overflow-y: auto;

  &>div, &>form {
    width: 100%;
  }
}

.modal-dismiss {
  align-self: end;
  font-size: 2rem;
  cursor: pointer;
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}