.loader-backdrop {
    position: fixed;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.loader {
    i {
        color: var(--main-color);
        font-size: 10rem;
    }
}