.black-block {
    position: relative;
    z-index: 2;
    padding: 3rem 7rem;
    background-color: var(--popup-bg);
    display: flex;
    flex-direction: column;
    align-items: center;
    // text-align: center;
    width: 450px;
    max-width: calc(100% - 2rem);
    border-radius: 1rem;
    max-height: 100vh;
    overflow-y: auto;
    max-width: 100vw;
    .close-btn {
        position: absolute;
        top: 1rem;
        right: 3rem;
        width: 2rem;
        cursor: pointer;
        i {
            font-size: 2rem;
        }
    }
    > * {
        width: 100%;
    }
    label {
        color: var(--text-color);
    }
}