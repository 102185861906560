.not-registered {
  padding: 0 5rem;
   &-header {
    text-align: center;
    h3 {
      font-size: 2rem;
    }
    p {
      font-size: 1.2rem;
    }
  }
  &-actions {
    display: flex;
    width: 100%;
    margin-top: 3.5rem;
    flex-direction: column;
    row-gap: 2.5rem;
  }
  &-cancel {
    text-align: center;
  }
}
