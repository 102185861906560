@media screen and (max-width: 1400px) {
  .modal-popup {
    width: 60%;

    .content {
      padding: 4rem 7rem;
    }
  }
}

@media screen and (max-width: 991px) {
  html {
    .container-fluid {
      // --bs-gutter-x: 8rem;
    }

    font-size: 13px;
  }

  .add-new-record-buttons {
    flex-direction: column;
    justify-content: space-between;
    column-gap: 0;
    row-gap: 1rem;
  }

  // Quran
  .quran {
    &-navigator {
      width: 25%;
    }

    &-view {
      width: 75%;
    }
  }

  // popup

  .modal-popup {
    width: 70%;

    .content {
      padding: 4rem 7rem;
    }
  }
}

@media screen and (max-width: 767px) {
  html {
    .container-fluid {
      // --bs-gutter-x: 5rem;
    }

    font-size: 11px;
  }

  .add-new-record-buttons {
    flex-direction: column;
    justify-content: space-between;
    column-gap: 0;
    row-gap: 1rem;
  }

  // Footer
  .footer {
    flex-direction: column;
    row-gap: 3rem;

    &-start {
      align-items: center;
      order: 2;

      &-links {
        flex-direction: column;
        row-gap: 3rem;
      }
    }

    &-end {
      row-gap: 2rem;
      order: 1;
    }
  }

  // Quran
  .quran {
    &-content {
      flex-direction: column;
      align-items: center;
    }

    &-navigator {
      display: none;
    }

    &-mobile-navigator {
      display: block;
    }

    &-view {
      width: 100%;
    }

  }

  // popup
  .black-block {
    width: 100vw !important;
  }

  // .content {
  //   width: 95vw;
  //   max-width: 100vw;
  // }
  .modal-popup {
    width: 80%;

    .content {
      padding: 3rem 4rem;
    }
  }

  // .audio-source {
  //   max-width: 100%;
  // }

  // .audio-record {
  //   audio {
  //     max-width: 21rem;
  //   }
  // }

  // .edit-profile {
  //   max-width: 500px;
  // }

  // navigation
  .navigation-links {
    justify-content: flex-start;
    .navbar {
      padding-inline-start: 3.5rem;
    }
    li.nav-item {
      a {
        width: 100%;
      }
    }
  }

}

@media screen and (max-width: 575px) {
  html {
    .container-fluid {
      // --bs-gutter-x: 0;
    }

    font-size: 10px;
  }

  // .content {
  //   width: 95vw;
  //   max-width: 100vw;
  // }
  .modal-popup {
    width: 100%;

    .content {
      padding: 2rem 2rem;
    }
  }

  // Home Subscribe
  .home-subscribe {
    &-input-group {
      flex-direction: column;
      row-gap: 1rem;
    }

    &-input {
      width: 100%;
    }

    &-send-btn {
      margin: 0;
      width: 100%;
    }
  }

  // Carousel
  .carousel {
    &-item {
      &:not(:first-child) {
        display: none;
      }
    }

    &-content {
      left: 1.9rem;
      right: 1.9rem;

      .register-now-btn {
        margin-bottom: 1.8rem;
      }
    }

    &-indicators {
      display: none;
    }

    .register-bar {
      display: none;
    }
  }

  .profile-header-following {
    padding: 1rem 5rem;
  }


  .add-new-record-buttons {
    flex-direction: column;
    justify-content: space-between;
    column-gap: 0;
    row-gap: 1rem;
  }

  // Quran
  .quran {
    &-view {
      &-surah {
        width: 80%;
      }
    }
  }

  // comments 
  .post {
    padding-bottom: 0;
  }

  .post-feedback {
    padding-bottom: 1.5rem;
  }

  .post-comments {
    margin: 0 -1.5rem;
    background-color: var(--main-bg);
  }

  .add-new-comment .add-comment {
    margin-inline-start: 0;
    // margin-top: 0;
  }

  .add-comment-actions {
    flex-direction: column;
    row-gap: 1rem;

    button {
      width: 100%;
    }
  }

  // .edit-profile {
  //   max-width: 250px;
  // }
}