.comment {
    &:not(:last-child) {
        .comment-content {
            &::before {
                right: -1.5em;
                left: unset;
            }
        }
    }
}

.profile-header {
    &-following {
        background: linear-gradient(to right, var(--gradient-main-dark) -2%, var(--gradient-main-light) 102%);
        &-followers {
            &::after {
                left: -5.5rem;
                right: unset;
            }
        }
    }
}

.carousel {
    &-content {
        .register-bar {
            background: linear-gradient(to right, var(--gradient-main-dark) -2%, var(--gradient-main-light) 102%);

        }
    }
}

// .audio-player > div > wave {
//     transform: rotate(180deg);
// }