@font-face {
    font-family: "Tajawal";
    src: url("../../fonts/Tajawal-Regular.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Tajawal";
    src: url("../../fonts/Tajawal-Bold.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "Quran";
    src: url("../../fonts/Quran.ttf") format("truetype");
    font-weight: normal;
}

body {
    font-family: var(--main-font-family);
    font-weight: 400;
}